import { Page } from 'layouts/Admin/Page';
import { useNavigate, useParams } from 'react-router';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import {
  useDeleteMediaSequence,
  useGetMediaSequence,
} from 'api/useMediaSequencesApi';
import { MediaSequenceUpdateForm } from './components/MediaSequence/MediaSequenceUpdateForm';
import { EMediaSequenceState, EPermission } from 'api/core';
import { MediaSequenceCreate } from './components/MediaSequence/MediaSequenceCreate';
import { useAuth } from 'auth/AuthProvider';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { isTemplate } from './media-sequence-utils';

export const MediaSequenceSettings = () => {
  const navigate = useNavigate();
  const { hasPermissions } = useAuth();

  const { id } = useParams<{ id: string }>();

  const { data } = useGetMediaSequence(id, undefined, true);

  if (!data) return null;

  const onDeleted = () => {
    navigate('/media-sequences');
  };

  const canUpdate =
    data.state === EMediaSequenceState.Draft &&
    hasPermissions([EPermission.MediaSequenceWrite]);
  const canDelete =
    data.state !== EMediaSequenceState.Draft ||
    hasPermissions([EPermission.MediaSequenceDelete]);

  return (
    <Page
      title={data.name}
      breadcrumbs={[
        {
          name: isTemplate(data) ? 'Smart Video Skabelon' : 'Smart Video',
        },
        { name: data.name },
        { name: 'Indstillinger' },
      ]}
    >
      <div className="flex pt-2">
        <PermissionProtectedComponent
          permissions={[EPermission.MediaSequenceDelete]}
        >
          <DeleteEntityById
            id={id}
            trigger={
              <button className="btn btn-error mr-2" disabled={!canDelete}>
                {data.state === EMediaSequenceState.Draft
                  ? 'Slet video'
                  : 'Arkiver video'}
              </button>
            }
            titleFn={(entityTitle) =>
              data.state === EMediaSequenceState.Draft
                ? `Slet ${entityTitle}?`
                : `Arkiver ${entityTitle}?`
            }
            descriptionFn={(entityTitle) =>
              data.state === EMediaSequenceState.Draft
                ? `Er du sikker på du vil slette '${entityTitle}'? Dette kan ikke fortrydes.`
                : `Er du sikker på du vil arkivere '${entityTitle}'? Dette kan ikke fortrydes, men du kan finde den under arkiverede videoer.`
            }
            onDeleted={onDeleted}
            titlePropertyKey={'name'}
            getter={useGetMediaSequence}
            deleter={useDeleteMediaSequence}
            disabled={!canDelete}
          />
        </PermissionProtectedComponent>
        <MediaSequenceCreate
          existingMediaSequence={data}
          trigger={
            <button className="btn btn-success mr-2">Lav kopi af video</button>
          }
        />
      </div>
      <MediaSequenceUpdateForm mediaSequence={data} disabled={!canUpdate} />
    </Page>
  );
};
