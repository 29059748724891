import {
  AssetResponse,
  EAssetType,
  EPermission,
  ETemplatePosition,
  MediaSequenceAssetResponse,
  MediaSequenceResponse,
} from 'api/core';
import { IconButton } from 'components/Button/IconButton';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
  SparklesIcon,
} from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SortableList } from 'components/SortableList/SortableList';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { MediaSequenceAssetMutationsTable } from 'pages/MediaOrbit/components/MediaSequenceAssetMutation/MediaSequenceAssetMutationsTable';
import { MediaSequenceAssetMutationCreate } from 'pages/MediaOrbit/components/MediaSequenceAssetMutation/MediaSequenceAssetMutationCreate';
import { MediaSequenceAssetUpdate } from './MediaSequenceAssetUpdate';
import { getAvailableAssetMutations } from 'utils/available-mutations';
import { MediaSequenceAssetCreate } from './MediaSequenceAssetCreate';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { twMerge } from 'tailwind-merge';
import { AssetUpload } from 'components/Form/AssetUpload';
import {
  useCreateMediaSequenceAsset,
  useDeleteMediaSequenceAsset,
  useGetMediaSequenceAsset,
  useUpdateMediaSequenceAsset,
  useUpdateMediaSequenceAssetOrder,
} from 'api/useMediaSequenceAssetsApi';
import {
  useGetMediaSequence,
  useGetMediaSequenceAssets,
} from 'api/useMediaSequencesApi';
import {
  isTemplate,
  MEDIA_SEQUENCE_ACCEPTED_FILES,
} from 'pages/MediaOrbit/media-sequence-utils';
import { useIsMobile } from 'utils/useIsMobile';
import { DropdownMenu } from 'components/DropdownMenu';
import { useAuth } from 'auth/AuthProvider';
import { useMediaSequenceSetup } from 'pages/MediaOrbit/components/Setup/MediaSequenceSetupProvider';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';
import { assetTypeTranslate } from 'utils/enum-translate';

interface MediaSequenceAssetSortableGridProps {
  mediaSequenceId: string;
  projectId?: string;
  disabled?: boolean;
}

export const MediaSequenceAssetSortableGrid = ({
  mediaSequenceId,
  projectId,
  disabled,
}: MediaSequenceAssetSortableGridProps) => {
  const { data } = useGetMediaSequence(mediaSequenceId);

  if (!data) return null;

  return (
    <MediaSequenceAssetSortableGridInner
      mediaSequence={data}
      projectId={projectId}
      disabled={disabled}
    />
  );
};

interface MediaSequenceAssetSortableGridInnerProps {
  mediaSequence: MediaSequenceResponse;
  projectId?: string;
  disabled?: boolean;
}

const MediaSequenceAssetSortableGridInner = ({
  mediaSequence,
  projectId,
  disabled,
}: MediaSequenceAssetSortableGridInnerProps) => {
  const { data: assets } = useGetMediaSequenceAssets(mediaSequence.id);
  const { steps, currentStepIndex, handlePrevious, handleNext } =
    useMediaSequenceSetup();

  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableText, setDisableText] = useState<string>('');

  const isMobile = useIsMobile();
  const [showCreate, setShowCreate] = useState(false);
  const [editTarget, setEditTarget] =
    useState<MediaSequenceAssetResponse | null>(null);
  const [deleteTarget, setDeleteTarget] =
    useState<MediaSequenceAssetResponse | null>(null);
  const [trimTarget, setTrimTarget] =
    useState<MediaSequenceAssetResponse | null>(null);

  const [assetTrimQueue, setAssetTrimQueue] = useState<
    MediaSequenceAssetResponse[]
  >([]);

  const { mutateAsync: updateAssetOrderAsync } =
    useUpdateMediaSequenceAssetOrder();
  const { mutateAsync: updateAsync } = useUpdateMediaSequenceAsset();
  const { mutateAsync: createAsync } = useCreateMediaSequenceAsset();

  const onRevalidateStep = useCallback(() => {
    if (isTemplate(mediaSequence)) {
      setDisableNext(false);
      setDisableText('');
    } else if (!assets || assets?.length === 0) {
      setDisableNext(true);
      setDisableText('Du skal tilføje mindst én fil');
    } else if (assets?.some((e) => e.isCopy)) {
      setDisableNext(true);
      setDisableText('Du mangler at vælge filer til din skabelon');
    } else {
      setDisableNext(false);
      setDisableText('');
    }
  }, [assets, mediaSequence]);

  useEffect(() => {
    onRevalidateStep();
  }, [assets, onRevalidateStep]);

  useEffect(() => {
    if (trimTarget) return;
    if (!assetTrimQueue.length) return;

    setTrimTarget(assetTrimQueue[0]);
    setAssetTrimQueue((ex) => ex.slice(1));
  }, [assetTrimQueue, trimTarget]);

  const { items, firstItem, lastItem } = useMemo(() => {
    const clonedAssets =
      assets?.slice().sort((a, b) => a.order - b.order) ?? [];
    const firstItemIndex = clonedAssets.findIndex(
      (item) => item.templatePosition === ETemplatePosition.First
    );
    let firstItem = null;
    if (firstItemIndex > -1) {
      firstItem = clonedAssets.splice(firstItemIndex, 1)[0];
    }
    const lastItemIndex = clonedAssets.findIndex(
      (item) => item.templatePosition === ETemplatePosition.Last
    );
    let lastItem = null;
    if (lastItemIndex > -1) {
      lastItem = clonedAssets.splice(lastItemIndex, 1)[0];
    }
    return { items: clonedAssets, firstItem, lastItem };
  }, [assets]);

  const onSorted = async (assets: MediaSequenceAssetResponse[]) => {
    await updateAssetOrderAsync({
      mediaSequenceAssetOrderUpdateRequest: {
        orderedAssetIds: assets.map((asset) => asset.id),
      },
    });
  };

  const onAssetMoveUp = async (asset: MediaSequenceAssetResponse) => {
    const currentOrder = asset.order;
    if (currentOrder === 1) return;

    const clonedArray = items.slice();
    const index = clonedArray.findIndex((a) => a.id === asset.id);
    if (index > 0) {
      // Swap elements and update their order
      const temp = clonedArray[index - 1];
      clonedArray[index - 1] = clonedArray[index];
      clonedArray[index] = temp;

      // Update order properties
      clonedArray[index - 1].order = currentOrder - 1;
      clonedArray[index].order = currentOrder;
    }

    await onSorted(clonedArray);
  };

  const onAssetMoveDown = async (asset: MediaSequenceAssetResponse) => {
    const currentOrder = asset.order;
    if (currentOrder === items.length) return;

    const clonedArray = items.slice();
    const index = clonedArray.findIndex((a) => a.id === asset.id);
    if (index < clonedArray.length - 1) {
      // Swap elements and update their order
      const temp = clonedArray[index + 1];
      clonedArray[index + 1] = clonedArray[index];
      clonedArray[index] = temp;

      // Update order properties
      clonedArray[index + 1].order = currentOrder + 1;
      clonedArray[index].order = currentOrder;
    }

    await onSorted(clonedArray);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const onAssetUploaded = async (asset: AssetResponse | null) => {
    if (!asset) return;
    if (asset.type !== EAssetType.Video && asset.type !== EAssetType.Image)
      return;

    const result = await createAsync({
      mediaSequenceId: mediaSequence.id,
      mediaSequenceAssetCreateRequest: {
        name: asset.originalFileName,
        assetId: asset.id,
      },
    });
    onAssetAddedOrChanged(result);
    onRevalidateStep();
  };

  const onAssetReplaceNewFile = async (
    asset: MediaSequenceAssetResponse,
    fileAsset: AssetResponse | null
  ) => {
    if (!fileAsset) return;
    if (
      fileAsset.type !== EAssetType.Video &&
      fileAsset.type !== EAssetType.Image
    )
      return;
    await updateAsync({
      id: asset.id,
      mediaSequenceAssetUpdateRequest: {
        ...asset,
        assetId: fileAsset.id,
      },
    });
    onAssetAddedOrChanged(asset);
  };

  const onAssetAddedOrChanged = (asset: MediaSequenceAssetResponse) => {
    if (asset.asset.type === EAssetType.Video) {
      setAssetTrimQueue((ex) => [...ex, asset]);
    }
  };

  return (
    <>
      <div
        id="assets-grid"
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-4"
      >
        <SortableList
          items={items}
          onChange={onSorted}
          startItems={[
            isMobile && items.length > 0 && (
              <AddAssetItem
                key={'add-asset-start'}
                mediaSequence={mediaSequence}
                projectId={projectId}
                disabled={disabled}
                setShowCreate={setShowCreate}
                fileInputRef={fileInputRef}
                onSuccess={onAssetAddedOrChanged}
              />
            ),
            firstItem && (
              <div>
                <SortableAsset
                  key={firstItem.id}
                  asset={firstItem}
                  assetAmount={items.length}
                  onAssetEdit={() => setEditTarget(firstItem)}
                  onAssetRemove={() => setDeleteTarget(firstItem)}
                  onAssetMoveUp={() => onAssetMoveUp(firstItem)}
                  onAssetMoveDown={() => onAssetMoveDown(firstItem)}
                  onAssetReplaceNewFile={onAssetReplaceNewFile}
                  projectId={mediaSequence._case?.project.id}
                  disabled={disabled}
                  mediaSequence={mediaSequence}
                />
              </div>
            ),
          ]}
          endItems={[
            lastItem && (
              <div>
                <SortableAsset
                  key={lastItem.id}
                  asset={lastItem}
                  assetAmount={items.length}
                  onAssetEdit={() => setEditTarget(lastItem)}
                  onAssetRemove={() => setDeleteTarget(lastItem)}
                  onAssetMoveUp={() => onAssetMoveUp(lastItem)}
                  onAssetMoveDown={() => onAssetMoveDown(lastItem)}
                  onAssetReplaceNewFile={onAssetReplaceNewFile}
                  projectId={mediaSequence._case?.project.id}
                  disabled={disabled}
                  mediaSequence={mediaSequence}
                />
              </div>
            ),
            <AddAssetItem
              key={'add-asset-end'}
              mediaSequence={mediaSequence}
              projectId={projectId}
              disabled={disabled}
              setShowCreate={setShowCreate}
              fileInputRef={fileInputRef}
              onSuccess={onAssetAddedOrChanged}
            />,
          ]}
          renderItem={(asset) => (
            <SortableList.Item id={asset.id}>
              <SortableAsset
                key={asset.id}
                asset={asset}
                assetAmount={items.length}
                onAssetEdit={() => setEditTarget(asset)}
                onAssetRemove={() => setDeleteTarget(asset)}
                onAssetMoveUp={() => onAssetMoveUp(asset)}
                onAssetMoveDown={() => onAssetMoveDown(asset)}
                onAssetReplaceNewFile={onAssetReplaceNewFile}
                projectId={mediaSequence._case?.project.id}
                disabled={disabled}
                mediaSequence={mediaSequence}
              />
            </SortableList.Item>
          )}
        />
      </div>

      <div className="flex flex-col mt-4 mb-8">
        <div id="step-navigation" className="join justify-center mt-2">
          <button
            className="join-item btn"
            onClick={handlePrevious}
            disabled={currentStepIndex === 1}
          >
            Forrige trin
          </button>
          <button
            className="join-item btn"
            onClick={handleNext}
            disabled={currentStepIndex >= steps.length || disableNext}
          >
            Næste trin
          </button>
        </div>
        {disableNext && disableText ? (
          <div className="text-center mt-2">
            <p className="text-sm text-gray-500">{disableText}</p>
          </div>
        ) : null}
      </div>

      {showCreate ? (
        <MediaSequenceAssetCreate
          mediaSequenceId={mediaSequence.id}
          projectId={projectId}
          isInitialOpen={true}
          onClosed={() => setShowCreate(false)}
          onSuccess={(asset) => {
            onRevalidateStep();
            onAssetAddedOrChanged(asset);
          }}
          disabled={disabled}
        />
      ) : null}

      {editTarget ? (
        <MediaSequenceAssetUpdate
          id={editTarget?.id}
          projectId={mediaSequence?._case?.project.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
          onSuccess={(asset) => {
            onRevalidateStep();
            onAssetAddedOrChanged(asset);
          }}
        />
      ) : null}

      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetMediaSequenceAsset}
          deleter={useDeleteMediaSequenceAsset}
          onDeleted={onRevalidateStep}
        />
      ) : null}

      <AssetUpload
        fileInputRef={fileInputRef}
        onAssetUploaded={onAssetUploaded}
        projectId={mediaSequence._case?.project.id}
        accept={MEDIA_SEQUENCE_ACCEPTED_FILES}
        allowMultiple
        convertPdfToImage={true}
      />

      {trimTarget ? (
        <MediaSequenceAssetMutationCreate
          asset={trimTarget}
          onClosed={() => setTrimTarget(null)}
          isInitialOpen
          canChangeMutationType={false}
          cancelButtonText="Fortsæt uden at klippe video"
        />
      ) : null}
    </>
  );
};

interface SortableAssetProps {
  asset: MediaSequenceAssetResponse;
  assetAmount: number;
  onAssetEdit: () => void;
  onAssetRemove: () => void;
  onAssetMoveUp: () => void;
  onAssetMoveDown: () => void;
  onAssetReplaceNewFile: (
    mediaSequenceAsset: MediaSequenceAssetResponse,
    asset: AssetResponse | null
  ) => void;
  projectId?: string;
  disabled?: boolean;
  mediaSequence: MediaSequenceResponse;
}

const SortableAsset = ({
  asset,
  assetAmount,
  onAssetEdit,
  onAssetRemove,
  onAssetMoveUp,
  onAssetMoveDown,
  onAssetReplaceNewFile,
  projectId,
  disabled,
  mediaSequence,
}: SortableAssetProps) => {
  const availableMutations = getAvailableAssetMutations(asset);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { hasPermissions } = useAuth();

  const uploadNewFilePermission = hasPermissions([
    EPermission.MediaSequenceEditorAssetsUploadFile,
  ]);
  const existingFilePermission = hasPermissions([
    EPermission.MediaSequenceEditorAssetsExistingFile,
  ]);

  const assetTrimMutation = asset.mutations.find(
    (e) => e.trimVideo !== undefined
  )?.trimVideo;

  return (
    <div
      id="assets-asset"
      className={twMerge(
        'card bg-base-200 shadow-md',
        !disabled && asset.isCopy && 'border-2 border-warning',
        asset.isTemplate && 'opacity-50'
      )}
    >
      <AssetUpload
        fileInputRef={fileInputRef}
        onAssetUploaded={(fileAsset) => onAssetReplaceNewFile(asset, fileAsset)}
        projectId={projectId}
        accept={MEDIA_SEQUENCE_ACCEPTED_FILES}
        convertPdfToImage={true}
      />
      {asset.isCopy ? (
        <div className="card-body p-3">
          <div className="flex flex-col gap-2 px-1 py-2">
            <div className="flex justify-between">
              <h2 className="card-title truncate text-lg">{asset.name}</h2>
              {!isTemplate(mediaSequence) || disabled ? null : (
                <div className="flex justify-between mt-3 p-0 space-x-6 md:space-x-3">
                  <IconButton
                    icon={
                      <div id="assets-edit-asset">
                        <AnimatedIcon
                          icon="pencil-icon"
                          className="h-8 md:h-6 w-8 md:w-6"
                        />
                      </div>
                    }
                    onClick={onAssetEdit}
                  />
                  <IconButton
                    icon={
                      <div id="assets-delete-asset">
                        <AnimatedIcon
                          icon="trash-icon"
                          className="h-8 md:h-6 w-8 md:w-6"
                        />
                      </div>
                    }
                    onClick={onAssetRemove}
                  />
                </div>
              )}
            </div>
            <p className="px-1">{asset.description}</p>
            <div className="flex justify-center items-center space-x-2">
              {/* Can only upload new files */}
              {uploadNewFilePermission && !existingFilePermission ? (
                <div
                  onClick={() => fileInputRef.current?.click()}
                  className="btn btn-primary flex flex-col justify-center items-center"
                >
                  Vælg fil
                </div>
              ) : null}

              {/* Can only upload existing */}
              {existingFilePermission && !uploadNewFilePermission ? (
                <div
                  onClick={onAssetEdit}
                  className="btn btn-primary flex flex-col justify-center items-center"
                >
                  Vælg fil
                </div>
              ) : null}

              {/* Can upload new files and existing */}
              {uploadNewFilePermission && existingFilePermission ? (
                <div className="dropdown dropdown-hover">
                  <div
                    id="assets-replace-asset"
                    tabIndex={0}
                    role="button"
                    className="btn btn-primary flex flex-col justify-center items-center"
                  >
                    Vælg fil
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <li>
                      <a onClick={() => fileInputRef.current?.click()}>
                        Upload ny fil
                      </a>
                    </li>
                    <li>
                      <a onClick={onAssetEdit}>Vælg eksisterende fil</a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {!asset.isCopy ? (
        <div className="grid grid-cols-1">
          <figure className="h-24 md:h-48 rounded-t-xl">
            <AssetPreviewModal
              asset={asset.asset}
              className="object-contain md:object-cover w-full h-full"
              trimStart={assetTrimMutation?.trimStartSeconds}
              trimEnd={assetTrimMutation?.trimEndSeconds}
              information={
                asset.asset.type === EAssetType.Video
                  ? 'Denne forhåndsvisning viser kun klip videoeffekten og ikke andre effekter'
                  : undefined
              }
            />
          </figure>
          <div className="card-body p-3">
            <div
              className={twMerge(
                'flex justify-between',
                asset.mutations.length > 0 &&
                  'pb-3 border-dotted border-gray-300 border-b-2'
              )}
            >
              <div className="w-full px-1">
                <p className="text-sm font-medium neutral-content text-gray-400">
                  {assetTypeTranslate(asset.asset.type)}
                </p>
                <div className="flex justify-between w-full">
                  <h2 className="card-title truncate text-lg">{asset.name}</h2>
                  {(asset.isTemplate && !isTemplate(mediaSequence)) ||
                  disabled ? null : (
                    <div className="flex justify-between mt-3 p-0 space-x-6 md:space-x-3">
                      <IconButton
                        icon={
                          <div id="assets-edit-asset">
                            <AnimatedIcon
                              icon="pencil-icon"
                              className="h-8 md:h-6 w-8 md:w-6"
                            />
                          </div>
                        }
                        onClick={onAssetEdit}
                      />
                      <IconButton
                        icon={
                          <div id="assets-delete-asset">
                            <AnimatedIcon
                              icon="trash-icon"
                              className="h-8 md:h-6 w-8 md:w-6"
                            />
                          </div>
                        }
                        onClick={onAssetRemove}
                      />
                    </div>
                  )}
                </div>
                {asset.isTemplate ? (
                  <p className="text-sm font-medium neutral-content text-gray-400">
                    {asset.description}
                  </p>
                ) : null}
              </div>
            </div>
            <MediaSequenceAssetMutationsTable
              asset={asset}
              projectId={projectId}
              disabled={
                (asset.isTemplate && !isTemplate(mediaSequence)) || disabled
              }
            />

            {disabled ? null : (
              <>
                {/* Only allowed moving items that don't have a fixed position */}
                {!asset.templatePosition ? (
                  <>
                    {/* Desktop */}
                    <div
                      id="assets-move-asset"
                      className="hidden md:flex absolute top-0 left-0 "
                    >
                      <SortableList.DragHandle />
                    </div>

                    {/* Mobile */}
                    <div
                      id="assets-move-asset-arrows"
                      className="flex flex-col md:hidden absolute left-0 top-0 bg-gray-300 rounded-tl-xl rounded-br-xl"
                    >
                      <button
                        className="btn btn-square btn-primary rounded-none rounded-tl-xl"
                        type="button"
                        onClick={onAssetMoveUp}
                        disabled={asset.order === 1}
                      >
                        <ArrowUpIcon size={20} />
                      </button>
                      <button
                        className="btn btn-square btn-primary rounded-none rounded-br-xl"
                        type="button"
                        onClick={onAssetMoveDown}
                        disabled={asset.order === assetAmount}
                      >
                        <ArrowDownIcon size={20} />
                      </button>
                    </div>
                  </>
                ) : null}

                <div className="absolute top-0 right-0">
                  <MediaSequenceAssetMutationCreate
                    asset={asset}
                    trigger={
                      <button
                        id="assets-add-asset-mutation"
                        className="btn btn-square btn-primary rounded-none rounded-tr-xl rounded-bl-xl"
                        type="button"
                      >
                        <SparklesIcon size={20} />
                      </button>
                    }
                    disabled={
                      disabled ||
                      availableMutations.length === 0 ||
                      (asset.isTemplate && !isTemplate(mediaSequence))
                    }
                    canChangeMutationType={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

interface AddAssetItemProps {
  mediaSequence: MediaSequenceResponse;
  projectId?: string;
  disabled?: boolean;
  setShowCreate: (show: boolean) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  onSuccess?: (asset: MediaSequenceAssetResponse) => void;
}

const AddAssetItem = ({
  mediaSequence,
  projectId,
  disabled,
  setShowCreate,
  fileInputRef,
  onSuccess,
}: AddAssetItemProps) => {
  const { hasPermissions } = useAuth();

  if (disabled) return null;

  const uploadNewFilePermission = hasPermissions([
    EPermission.MediaSequenceEditorAssetsUploadFile,
  ]);
  const existingFilePermission = hasPermissions([
    EPermission.MediaSequenceEditorAssetsExistingFile,
  ]);

  if (!uploadNewFilePermission && !existingFilePermission) return null;

  return (
    <div className="card bg-base-200 md:h-60 shadow-md flex flex-col gap-2 justify-center items-center">
      {/* Can only upload new files */}
      {uploadNewFilePermission && !existingFilePermission ? (
        <div
          id="assets-add-asset"
          tabIndex={0}
          role="button"
          className="btn btn-block md:btn-circle btn-primary md:w-32 md:h-32 flex flex-col justify-center items-center"
          onClick={() => fileInputRef.current?.click()}
        >
          <span className="hidden md:flex">
            <PlusIcon size={48} />
          </span>
          <p>Tilføj fil</p>
        </div>
      ) : null}

      {/* Can only upload existing */}
      {existingFilePermission && !uploadNewFilePermission ? (
        <MediaSequenceAssetCreate
          trigger={
            <div
              id="assets-add-asset"
              tabIndex={0}
              role="button"
              className="btn btn-block md:btn-circle btn-primary md:w-32 md:h-32 flex flex-col justify-center items-center"
            >
              <span className="hidden md:flex">
                <PlusIcon size={48} />
              </span>
              <p>Tilføj fil</p>
            </div>
          }
          mediaSequenceId={mediaSequence.id}
          projectId={projectId}
          onClosed={() => setShowCreate(false)}
          disabled={disabled}
          onSuccess={onSuccess}
        />
      ) : null}

      {/* Can upload new files and existing */}
      {uploadNewFilePermission && existingFilePermission ? (
        <DropdownMenu
          trigger={
            <div
              id="assets-add-asset"
              tabIndex={0}
              role="button"
              className="btn btn-block md:btn-circle btn-primary md:w-32 md:h-32 flex flex-col justify-center items-center"
            >
              <span className="hidden md:flex">
                <PlusIcon size={48} />
              </span>
              <p>Tilføj fil</p>
            </div>
          }
          items={[
            <a key={1} onClick={() => fileInputRef.current?.click()}>
              Upload ny fil
            </a>,
            <MediaSequenceAssetCreate
              key={2}
              trigger={<a>Vælg eksisterende fil</a>}
              mediaSequenceId={mediaSequence.id}
              projectId={projectId}
              onClosed={() => setShowCreate(false)}
              disabled={disabled}
              onSuccess={onSuccess}
            />,
          ]}
        />
      ) : null}
    </div>
  );
};
