import {
  AssetSimpleResponse,
  EAssetType,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationResponse,
  MediaSequenceBranchMutationUpdateRequest,
  MediaSequenceResponse,
} from 'api/core';
import { useGetMediaSequenceAssets } from 'api/useMediaSequencesApi';
import { AudioPreview } from 'components/Audio/AudioPreview';
import { AssetUpload } from 'components/Form/AssetUpload';
import { VolumePicker } from 'components/Form/VolumePicker';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const VOLUME_DEFAULT = 0.03;
const VOLUME_STEP = 0.01;
const VOLUME_MIN = 0.01;
const VOLUME_MAX = 0.1;
const VOLUME_SHOW_ZERO_STEP = true;

interface ActionButtonProps {
  show: boolean;
  disabled: boolean;
  text: string;
  onClick?: () => void;
}

interface AddBackgroundMusicProps {
  targetBranchMutation?: MediaSequenceBranchMutationResponse;
  projectId?: string;
  inDialog?: boolean;
  mediaSequence: MediaSequenceResponse;
  saveButton?: ActionButtonProps;
  deleteButton?: ActionButtonProps;
}

export const AddBackgroundMusic = ({
  targetBranchMutation,
  projectId,
  inDialog,
  mediaSequence,
  saveButton,
  deleteButton,
}: AddBackgroundMusicProps) => {
  const {
    register,
    setValue,
    watch,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceBranchMutationCreateRequest
    | MediaSequenceBranchMutationUpdateRequest
  >();

  const { data: assets } = useGetMediaSequenceAssets(mediaSequence.id);
  const videoUrls =
    assets
      ?.filter((e) => e.asset.type === EAssetType.Video)
      .sort((a, b) => a.order - b.order)
      .map((e) => e.asset.url) ?? [];

  const backgroundMusicfileInputRef = useRef<HTMLInputElement>(null);
  const [
    initialSelectedBackgroundMusicAsset,
    setInitialSelectedBackgroundMusicAsset,
  ] = useState<AssetSimpleResponse | undefined>(
    targetBranchMutation?.addBackgroundMusic?.asset
  );

  const onBackgroundAudioSelected = (asset: AssetSimpleResponse | null) => {
    if (asset) {
      setValue('addBackgroundMusic.assetId', asset.id);
      setValue('addBackgroundMusic.backgroundAudioVolume', VOLUME_DEFAULT, {
        shouldDirty: true,
      });
      setInitialSelectedBackgroundMusicAsset(asset);
    } else {
      setInitialSelectedBackgroundMusicAsset(undefined);
    }
  };

  return (
    <>
      <div className="flex flex-col xl:flex-row w-full space-y-4 lg:space-y-4 md:space-x-4">
        {/* Controls for the audio file */}
        <div className="flex flex-col w-full items-center space-y-4 md:space-y-4 md:space-x-0">
          <div id="audio-select" className="w-full">
            <label className="label">
              <span className="label-text">Lydfil</span>
            </label>
            <AssetSelectSingleAsync
              types={[EAssetType.Audio]}
              onSelected={onBackgroundAudioSelected}
              initialValue={initialSelectedBackgroundMusicAsset}
              showCreateOption
              onCreateOptionSelected={() =>
                backgroundMusicfileInputRef.current?.click()
              }
              inDialog={inDialog}
              disabled={disabled}
            />
            <input
              {...register('addBackgroundMusic.assetId', { required: true })}
              type="text"
              className="hidden"
            />
          </div>

          {initialSelectedBackgroundMusicAsset ? (
            <div id="audio-volume" className="w-full">
              <VolumePicker
                registerFn={() =>
                  register('addBackgroundMusic.backgroundAudioVolume', {
                    required: true,
                  })
                }
                watchFn={() =>
                  watch('addBackgroundMusic.backgroundAudioVolume')
                }
                setFn={(value: number) =>
                  setValue('addBackgroundMusic.backgroundAudioVolume', value)
                }
                min={VOLUME_MIN}
                max={VOLUME_MAX}
                step={VOLUME_STEP}
                defaultVolume={VOLUME_DEFAULT}
                showZeroStep={VOLUME_SHOW_ZERO_STEP}
                showCurrentValueInTop={false}
              />
            </div>
          ) : null}

          <div className="flex space-x-4 pt-4">
            {deleteButton ? (
              <button
                id="audio-remove"
                className="btn btn-error"
                disabled={disabled || deleteButton.disabled}
                type="button"
                onClick={deleteButton.onClick}
              >
                {deleteButton.text}
              </button>
            ) : null}

            {saveButton ? (
              <button
                id="audio-update"
                className="btn btn-primary"
                disabled={disabled || saveButton.disabled}
                type="submit"
                onClick={saveButton.onClick}
              >
                {saveButton.text}
              </button>
            ) : null}
          </div>
        </div>

        {/* Audio preview */}
        <div className="w-full">
          {initialSelectedBackgroundMusicAsset && videoUrls.length === 0 ? (
            <AudioPreview
              className="w-full"
              asset={initialSelectedBackgroundMusicAsset}
              volume={watch('addBackgroundMusic.backgroundAudioVolume')}
            />
          ) : initialSelectedBackgroundMusicAsset && videoUrls.length > 0 ? (
            <>
              <VideoWithBackgroundMusicPreview
                videoUrls={videoUrls}
                audioUrl={initialSelectedBackgroundMusicAsset.url}
                audioVolume={watch('addBackgroundMusic.backgroundAudioVolume')}
                wrapAround
              />
              <div className="text-sm text-gray-500 mt-2">
                Her er en <span className="font-bold ">forhåndsvisning</span> af
                baggrundsmusikken kombineret med et videoklip fra sekvensen.
                Bemærk venligst, at det endelige resultat kan variere.
              </div>
            </>
          ) : (
            <div className="w-full h-16 mt-4 bg-gray-200 flex items-center justify-center">
              Vælg en lydfil i dropdown til venstre for at forhåndsvise
            </div>
          )}
        </div>
      </div>

      <AssetUpload
        fileInputRef={backgroundMusicfileInputRef}
        projectId={projectId}
        onAssetUploaded={onBackgroundAudioSelected}
        accept="audio/*"
      />
    </>
  );
};

interface VideoWithBackgroundMusicPreviewProps {
  videoUrls: string[];
  audioUrl: string;
  audioVolume: number; // 0.01 - 1.00
  wrapAround: boolean;
}

export const VideoWithBackgroundMusicPreview = ({
  videoUrls,
  audioUrl,
  audioVolume,
  wrapAround,
}: VideoWithBackgroundMusicPreviewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videoUrl, setVideoUrl] = useState(videoUrls[currentVideoIndex]);

  const [canGoNext, setCanGoNext] = useState(videoUrls.length > 1);
  const [canGoPrev, setCanGoPrev] = useState(
    videoUrls.length > 1 && wrapAround
  );

  useEffect(() => {
    setVideoUrl(videoUrls[currentVideoIndex]);

    if (wrapAround) {
      setCanGoNext(videoUrls.length > 1);
      setCanGoPrev(videoUrls.length > 1);
    } else {
      setCanGoNext(currentVideoIndex < videoUrls.length - 1);
      setCanGoPrev(currentVideoIndex > 0);
    }

    audioRef.current?.pause();
  }, [currentVideoIndex, videoUrls, wrapAround]);

  useEffect(() => {
    const video = videoRef.current;
    const audio = audioRef.current;

    if (video && audio) {
      // Set the initial audio volume
      audio.volume = audioVolume;

      // Sync play/pause between video and audio
      const syncPlayPause = () => {
        if (video.paused) {
          audio.pause();
        } else {
          audio.play();
        }
      };

      // Sync audio currentTime with video when seeking
      const syncCurrentTime = () => {
        audio.currentTime = video.currentTime;
      };

      // Loop audio if it ends before the video
      const loopAudio = () => {
        if (!video.paused && audio.ended) {
          audio.currentTime = 0;
          audio.play();
        }
      };

      // Attach event listeners
      video.addEventListener('play', syncPlayPause);
      video.addEventListener('pause', syncPlayPause);
      video.addEventListener('seeked', syncCurrentTime);
      audio.addEventListener('ended', loopAudio);

      // Clean up event listeners on unmount
      return () => {
        video.removeEventListener('play', syncPlayPause);
        video.removeEventListener('pause', syncPlayPause);
        video.removeEventListener('seeked', syncCurrentTime);
        audio.removeEventListener('ended', loopAudio);
      };
    }
  }, [audioVolume]);

  const handleVideoChange = (newIndex: number) => {
    setCurrentVideoIndex((newIndex + videoUrls.length) % videoUrls.length);

    // Reset audio and video to start
    if (audioRef.current && videoRef.current) {
      audioRef.current.currentTime = 0;
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div className="relative">
      {/* Video element */}
      <video
        ref={videoRef}
        src={videoUrl}
        controls
        className="w-full h-96"
        crossOrigin="anonymous"
      />

      {/* Hidden audio element */}
      <audio ref={audioRef} src={audioUrl} crossOrigin="anonymous" />

      {/* Navigation buttons */}
      {canGoPrev && (
        <button
          type="button"
          onClick={() => handleVideoChange(currentVideoIndex - 1)}
          title="Forrige video"
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-primary bg-opacity-90 text-accent p-2 hover:bg-primary hover:text-white hover:bg-opacity-100 transition duration-200"
        >
          <ChevronLeftIcon className="inline-block" />
        </button>
      )}
      {canGoNext && (
        <button
          type="button"
          onClick={() => handleVideoChange(currentVideoIndex + 1)}
          title="Næste video"
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-primary bg-opacity-90 text-accent p-2 hover:bg-primary hover:text-white hover:bg-opacity-100 transition duration-200"
        >
          <ChevronRightIcon className="inline-block" />
        </button>
      )}
    </div>
  );
};
