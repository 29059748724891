import { useEffect, useState } from 'react';
import Select, { MultiValue, Options } from 'react-select';

interface OptionType<T> {
  value: T;
  label: string;
  searchValue: string;
}

interface EntitySelectMultipleProps<
  T extends { id: string | number },
  U = unknown,
> {
  data: T[];
  renderFormat: (input: T) => React.ReactNode;
  onSelect: (output: T[] | null) => void;
  initialValue?: T[];
  menuPlacement?: 'auto' | 'bottom' | 'top';
  searchPropertyKey: keyof T;
  inDialog?: boolean;
  disabled?: boolean;
  formWatch?: U[];
  formWatchMapper?: (input: U[]) => T[];
  maxSelections?: number;
  isOptionDisabled?:
    | ((option: OptionType<T>, selectValue: Options<OptionType<T>>) => boolean)
    | undefined;
}

export function EntitySelectMultiple<
  T extends { id: string | number },
  U = unknown,
>({
  data,
  renderFormat,
  onSelect,
  initialValue = [],
  menuPlacement = 'auto',
  searchPropertyKey,
  inDialog,
  disabled,
  formWatch,
  formWatchMapper,
  maxSelections,
  isOptionDisabled,
}: EntitySelectMultipleProps<T, U>) {
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(
    null
  );
  const [selectedOptions, setSelectedOptions] = useState<OptionType<T>[]>(
    initialValue.map((value) => ({
      value,
      label: '',
      searchValue: value[searchPropertyKey] as unknown as string,
    }))
  );

  useEffect(() => {
    if (formWatch === undefined || formWatchMapper === undefined) return;

    const values = formWatchMapper?.(formWatch ?? []);
    setSelectedOptions(
      values.map((value) => ({
        value,
        label: '',
        searchValue: value[searchPropertyKey] as unknown as string,
      }))
    );
  }, [formWatch, formWatchMapper, setSelectedOptions, searchPropertyKey]);

  useEffect(() => {
    if (inDialog) {
      setMenuPortalTarget(document.querySelector('dialog'));
    } else {
      setMenuPortalTarget(document.querySelector('body'));
    }
  }, [inDialog]);

  return (
    <div className="form-control w-full">
      <Select
        id="entity-select-multiple"
        inputId="entity-select-multiple-input"
        options={data.map((value) => ({
          value,
          label: '',
          searchValue: value[searchPropertyKey] as unknown as string,
        }))}
        value={selectedOptions}
        onChange={(selectedOption: MultiValue<OptionType<T>>) => {
          if (maxSelections && selectedOption.length > maxSelections) {
            return;
          }
          const mappedOptions = selectedOption
            ? selectedOption.map((option) => option)
            : [];
          setSelectedOptions(mappedOptions);
          onSelect(mappedOptions.map((option) => option.value) ?? null);
        }}
        formatOptionLabel={(option) => <>{renderFormat(option.value)}</>}
        placeholder="Skriv for at søge..."
        isClearable
        isMulti
        closeMenuOnSelect={false}
        noOptionsMessage={() => 'Ingen resultater fundet'}
        menuPlacement={menuPlacement}
        getOptionValue={(option) => option.value.id.toString()}
        filterOption={(option, rawInput) => {
          const input = rawInput.toLowerCase();
          return option.data.searchValue.toLowerCase().includes(input);
        }}
        menuPortalTarget={menuPortalTarget}
        isDisabled={disabled}
        isOptionDisabled={
          isOptionDisabled ??
          (maxSelections === undefined || selectedOptions.length < maxSelections
            ? undefined
            : (option) =>
                selectedOptions.length >= maxSelections &&
                !selectedOptions.includes(option))
        }
      />
    </div>
  );
}
