import {
  EEntityState,
  EMediaSequenceType,
  EPermission,
  FolderResponse,
  MediaSequenceCategoryResponse,
  MediaSequenceCreateRequest,
  MediaSequenceResponse,
  UserSimpleResponse,
} from 'api/core';
import { useSearchFolders } from 'api/useFoldersApi';
import { useGetMediaSequenceCategories } from 'api/useMediaSequenceCategoriesApi';
import {
  useCloneMediaSequence,
  useCreateMediaSequence,
  useSearchMediaSequences,
  useSearchMediaSequenceTemplates,
} from 'api/useMediaSequencesApi';
import { useSearchUsers } from 'api/useUsersApi';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import {
  AddressSuggestion,
  AutocompleteAddress,
} from 'components/Form/AutocompleteAddress';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { AnimatedIconKey } from 'components/Icon/AnimatedIcon';
import { InlineLoading } from 'components/Loading';
import { EntitySelectSingleAsync } from 'components/Select/EntitySelectSingleAsync';
import { isTemplate } from 'pages/MediaOrbit/media-sequence-utils';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';

interface MediaSequenceCreateFormProps {
  existingMediaSequence?: MediaSequenceResponse;
  existingCaseId?: string;
  existingFolderId?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  inDialog?: boolean;
}

export const MediaSequenceCreateForm = ({
  existingMediaSequence,
  existingCaseId,
  existingFolderId,
  onSuccess,
  onCancel,
  inDialog,
}: MediaSequenceCreateFormProps) => {
  const { data: allTemplates } = useSearchMediaSequenceTemplates();
  const { data: allCategories } = useGetMediaSequenceCategories();

  const [categories, setCategories] = useState<MediaSequenceCategoryResponse[]>(
    []
  );

  const [templates, setTemplates] = useState<MediaSequenceResponse[]>([]);

  const [selectedCategory, setSelectedCategory] =
    useState<MediaSequenceCategoryResponse | null>(null);

  const [isUsingTemplate, setIsUsingTemplate] = useState<boolean>(false);

  const [loadingMessage, setLoadingMessage] = useState<{
    message: string;
    icon: AnimatedIconKey;
  } | null>(null);

  const [selectedMediaSequence, setSelectedMediaSequence] =
    useState<MediaSequenceResponse | null>(existingMediaSequence ?? null);

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AddressSuggestion>();

  const navigate = useNavigate();
  const { mutateAsync: createAsync, isPending } = useCreateMediaSequence();
  const { mutateAsync: cloneAsync, isPending: IsPendingClone } =
    useCloneMediaSequence();

  // Automatically select the first template if there are any, and no existing case or media sequence is provided
  useEffect(() => {
    if (
      categories &&
      categories.length > 0 &&
      !existingCaseId &&
      !existingMediaSequence
    ) {
      setIsUsingTemplate(true);
      setSelectedCategory(categories[0]);
    }
  }, [categories, existingCaseId, existingMediaSequence]);

  useEffect(() => {
    if (!allCategories || !allTemplates) {
      return;
    }

    const categoryIds = new Set(
      allTemplates
        .map((template) => template.mediaSequenceCategoryId)
        .filter(Boolean)
    );

    setCategories(
      allCategories.data
        .filter((category) => categoryIds.has(category.id))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [allCategories, allTemplates]);

  useEffect(() => {
    if (!selectedCategory || !allTemplates) {
      setTemplates([]);
      return;
    }

    const templates = allTemplates.filter(
      (template) => template.mediaSequenceCategoryId === selectedCategory.id
    );

    setTemplates(templates);

    if (templates.length > 0) {
      setIsUsingTemplate(true);
      setSelectedMediaSequence(templates[0]);
    }
  }, [selectedCategory, allTemplates]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<MediaSequenceCreateRequest>({
    defaultValues: {
      name: existingMediaSequence?.name,
      caseId: existingCaseId ?? null,
      folderId: existingMediaSequence?.folderId ?? existingFolderId ?? null,
      metaMappingUserId: existingMediaSequence?.metaMappingUserId ?? null,
    },
  });

  const onSubmit = handleSubmit(async (result) => {
    if (selectedSuggestion && selectedSuggestion.forslagstekst) {
      result.name = selectedSuggestion.forslagstekst;
      result.dawaAddressId = selectedSuggestion.data.id;
    }
    result.entityState = EEntityState.Active;

    setLoadingMessage({
      message: 'Opretter video...',
      icon: 'loading-icon',
    });

    if (selectedMediaSequence) {
      const cloneResult = await cloneAsync({
        id: selectedMediaSequence.id,
        mediaSequenceCloneRequest: result,
      });
      finializeSubmission(cloneResult);
    } else {
      const mediaSequence = await createAsync({
        mediaSequenceCreateRequest: result,
      });
      finializeSubmission(mediaSequence);
    }
  });

  const finializeSubmission = async (mediaSequence: MediaSequenceResponse) => {
    setLoadingMessage(null);
    onSuccess?.();
    navigate(`/media-sequences/${mediaSequence.id}`);
  };

  const onUserSelected = (userResponse: UserSimpleResponse | null) => {
    setValue('metaMappingUserId', userResponse?.id, { shouldValidate: true });
  };

  const onFolderSelected = (folderResponse: FolderResponse | null) => {
    setValue('folderId', folderResponse?.id, { shouldValidate: true });
  };

  if (!allTemplates || !allCategories) {
    return <InlineLoading text="Henter skabeloner..." icon="loading-icon" />;
  }

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      {categories.length > 0 &&
      !(
        existingMediaSequence &&
        existingMediaSequence.type !== EMediaSequenceType.Template &&
        existingMediaSequence.type !== EMediaSequenceType.StrictTemplate
      ) ? (
        <div className="form-control">
          <LabelWithHelperText
            label="Skabelonskategori"
            helperText="Du har mulighed for at vælge en skabelon som udgangspunkt for din nye video. Dette vil kopiere alle indstillinger fra den valgte skabelon. Vælg en kategori for at se skabelonerne i kategorien."
            inDialog={inDialog}
          />
          <ul className="menu menu-md pl-0 menu-horizontal m-0 pt-0 gap-1 before:content-none">
            {categories.map((category) => (
              <li key={category.id}>
                <a
                  className={twMerge(
                    'border border-gray-300',
                    selectedCategory?.id === category.id && 'active'
                  )}
                  onClick={() => {
                    setSelectedCategory(category);
                  }}
                >
                  {category.name}
                </a>
              </li>
            ))}
            <li>
              <a
                className={twMerge(
                  'border border-gray-300',
                  !selectedCategory && 'active',
                  selectedCategory && 'opacity-40'
                )}
                onClick={() => {
                  setIsUsingTemplate(false);
                  setSelectedCategory(null);
                  setSelectedMediaSequence(null);
                }}
              >
                Ingen skabelon
              </a>
            </li>
          </ul>
        </div>
      ) : null}

      {templates.length > 0 &&
      !(
        existingMediaSequence &&
        existingMediaSequence.type !== EMediaSequenceType.Template &&
        existingMediaSequence.type !== EMediaSequenceType.StrictTemplate
      ) ? (
        <div className="form-control">
          <LabelWithHelperText
            label="Skabelon"
            helperText="Du har mulighed for at vælge en skabelon som udgangspunkt for din nye video. Dette vil kopiere alle indstillinger fra den valgte skabelon."
            inDialog={inDialog}
          />
          <ul className="menu menu-md pl-0 menu-horizontal m-0 pt-0 gap-1 before:content-none">
            {templates.map((template) => (
              <li key={template.id}>
                <a
                  className={twMerge(
                    'border border-gray-300',
                    isUsingTemplate &&
                      selectedMediaSequence?.id === template.id &&
                      'active'
                  )}
                  onClick={() => {
                    setSelectedMediaSequence(template);
                    setIsUsingTemplate(true);
                  }}
                >
                  {template.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      <AutocompleteAddress
        label={
          <LabelWithHelperText
            label="Adresse (eller titel)"
            helperText="Indtast en adresse for at hente stamdata, eller en titel for at bruge den som videonavn."
            inDialog={inDialog}
          />
        }
        wrapLabelInLabelTag={false}
        registration={() => register('name', { required: true })}
        formWatch={watch('name') || ''}
        formSetValue={(value: string) =>
          setValue('name', value, { shouldValidate: true })
        }
        inputClassName="input input-bordered"
        onSuggestionSelectedFn={setSelectedSuggestion}
      />

      <PermissionProtectedComponent
        permissions={[EPermission.UserSimpleSearch]}
      >
        <div className="form-control">
          <LabelWithHelperText
            label={<p>Tilknyt mægler</p>}
            helperText="Der bliver automatisk indhentet stamdata for den valgte mægler."
            inDialog={inDialog}
          />
          <EntitySelectSingleAsync<UserSimpleResponse, { searchTerm?: string }>
            useSearchFunction={useSearchUsers}
            searchFunctionOrder={(a, b) => a.name.localeCompare(b.name)}
            searchParams={{}}
            renderSuggestion={(userSuggestion) => (
              <>
                {userSuggestion.name}{' '}
                {userSuggestion.title ? `(${userSuggestion.title})` : undefined}
              </>
            )}
            initialValue={(u) =>
              u.id === existingMediaSequence?.metaMappingUserId
            }
            onSuggestionSelected={onUserSelected}
            inDialog={inDialog}
          />

          <input
            {...register('metaMappingUserId')}
            type="text"
            className="input input-bordered hidden"
          />
        </div>
      </PermissionProtectedComponent>

      <PermissionProtectedComponent permissions={[EPermission.FolderRead]}>
        <div className="form-control">
          <LabelWithHelperText
            label={<p>Tilknyt mappe</p>}
            helperText="Vælg hvilken mappe videoen skal tilknyttes."
            inDialog={inDialog}
          />
          <EntitySelectSingleAsync<FolderResponse, { searchTerm?: string }>
            useSearchFunction={useSearchFolders}
            searchFunctionOrder={(a, b) => a.name.localeCompare(b.name)}
            searchParams={{}}
            renderSuggestion={(folderSuggestion) => (
              <>{folderSuggestion.name}</>
            )}
            initialValue={(u) =>
              u.id === existingMediaSequence?.folderId ||
              u.id === existingFolderId
            }
            onSuggestionSelected={onFolderSelected}
            inDialog={inDialog}
          />

          <input
            {...register('metaMappingUserId')}
            type="text"
            className="input input-bordered hidden"
          />
        </div>
      </PermissionProtectedComponent>

      {!isUsingTemplate &&
      existingMediaSequence &&
      existingMediaSequence.type !== EMediaSequenceType.Template &&
      existingMediaSequence.type !== EMediaSequenceType.StrictTemplate ? (
        <div className="form-control">
          <LabelWithHelperText
            label="Brug eksisterende video som skabelon"
            helperText="Du har mulighed for at vælge en eksisterende video som udgangspunkt for din nye video. Dette vil kopiere alle indstillinger fra den valgte video."
            inDialog={inDialog}
          />
          <EntitySelectSingleAsync<
            MediaSequenceResponse,
            { searchTerm?: string }
          >
            useSearchFunction={useSearchMediaSequences}
            searchParams={{}}
            renderSuggestion={(mediaSequenceSuggestion) => (
              <>
                {isTemplate(mediaSequenceSuggestion) ? (
                  <span className="font-bold mr-1">[Skabelon]</span>
                ) : null}
                {mediaSequenceSuggestion.name}
              </>
            )}
            onSuggestionSelected={setSelectedMediaSequence}
            initialValue={existingMediaSequence}
            inDialog={inDialog}
            disabled={true}
          />
        </div>
      ) : null}

      {
        // TODO: Remove when loading indicator supports being on top of modals
      }
      {loadingMessage ? (
        <InlineLoading
          text={loadingMessage.message}
          icon={loadingMessage.icon}
        />
      ) : null}

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPending || IsPendingClone || !isValid}
        >
          Opret
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
