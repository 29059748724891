import { useEffect } from 'react';
import { Page } from 'layouts/Admin/Page';
import { loadScript } from 'utils/load-script';
import { useGetProject } from 'api/useProjectsApi';
import { useParams } from 'react-router';

export const ProjectEstateOrbitPreview = () => {
  const { id } = useParams<{ id: string }>();
  const { data: project } = useGetProject(id);

  useEffect(() => {
    const script = loadScript(
      `${process.env.REACT_APP_MEDIA_ORBIT_MODULE_URL}/injection.js`
    );

    script.onload = () => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList') {
            const existingElement = document.getElementById('estate-orbit');
            if (existingElement) {
              const projectId =
                existingElement.getAttribute('data-project-id') || '';
              const estateOrbitElement = document.createElement(
                'estate-orbit-element'
              );
              estateOrbitElement.setAttribute('data-project-id', projectId);
              existingElement.replaceWith(estateOrbitElement);
            }
          }
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });

      // Manually trigger DOMContentLoaded event
      document.dispatchEvent(
        new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true,
        })
      );
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Page
      title={project?.name}
      breadcrumbs={[
        { name: 'Projekter' },
        { name: project?.name ?? '' },
        { name: 'Boligvælger' },
        { name: 'Forhåndsvisning' },
      ]}
    >
      <div id="estate-orbit" data-project-id={id}></div>
    </Page>
  );
};
