import { useCreateTextEntriesUsingAi } from 'api/useTextCollectionsApi';
import {
  AddressSuggestion,
  AutocompleteAddress,
} from 'components/Form/AutocompleteAddress';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FullscreenLoadingWithText, InlineLoading } from 'components/Loading';
import { twMerge } from 'tailwind-merge';
import {
  ETextPurpose,
  EWritingStyle,
  EWritingTargetAudience,
  TextCollectionResponse,
} from 'api/core';
import {
  textPurposeTranslate,
  writingStyleTranslate,
  writingTargetAudienceTranslate,
} from 'utils/enum-translate';
import { AnimatedIconKey } from 'components/Icon/AnimatedIcon';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { EntitySelectMultiple } from 'components/Select/EntitySelectMultiple';

interface SmartTextFormProps {
  onSuccess?: (id: string) => void;
  onCancel?: () => void;
  copyOf?: TextCollectionResponse;
}

enum EStep {
  Address,
  SellingPoints,
  Preferences,
}

interface ITargetAudience {
  id: EWritingTargetAudience;
  name: string;
}

const translateStep = (step: EStep) => {
  switch (step) {
    case EStep.Address:
      return 'Adresse';
    case EStep.SellingPoints:
      return 'Købsargumenter';
    case EStep.Preferences:
      return 'Skrivestil';
    default:
      return 'Ukendt';
  }
};

interface OutputFormat {
  key: ETextPurpose;
  enabled: boolean;
  length: number;
  minLength: number;
  maxLength: number;
}

export const SmartTextCreateForm = ({
  onSuccess,
  onCancel,
  copyOf,
}: SmartTextFormProps) => {
  const MIN_SELLING_POINTS = 3;
  const MAX_SELLING_POINTS = 5;
  const STEPS = [EStep.Address, EStep.SellingPoints, EStep.Preferences];

  const { mutateAsync: createTextEntriesUsingAiAsync } =
    useCreateTextEntriesUsingAi();

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AddressSuggestion>();

  const [currentStep, setCurrentStep] = useState(STEPS[0]);

  const [sellingPoints, setSellingPoints] = useState<string[]>(
    copyOf?.sellingPoints?.length
      ? copyOf.sellingPoints.map((sp) => sp.value)
      : new Array(MIN_SELLING_POINTS).fill('')
  );

  const targetAudiences: ITargetAudience[] = Object.values(
    EWritingTargetAudience
  )
    .filter((e) => e !== EWritingTargetAudience.Unspecified)
    .map((ws) => ({
      id: ws,
      name: writingTargetAudienceTranslate(ws),
    }));

  const [outputFormats, setOutputFormats] = useState<OutputFormat[]>([
    {
      key: ETextPurpose.SocialMedia,
      enabled: true,
      length: 300,
      minLength: 200,
      maxLength: 800,
    },
    {
      key: ETextPurpose.Website,
      enabled: true,
      length: 900,
      minLength: 400,
      maxLength: 1500,
    },
    {
      key: ETextPurpose.ShopWindow,
      enabled: true,
      length: 500,
      minLength: 300,
      maxLength: 1000,
    },
  ]);

  const [loadingMessage, setLoadingMessage] = useState<{
    message: string;
    icon: AnimatedIconKey;
  } | null>(null);
  const { register, handleSubmit, watch, setValue } = useForm<{
    address: string;
    writingStyle: EWritingStyle;
    writingTargetAudiences: EWritingTargetAudience[];
  }>({
    defaultValues: {
      address: copyOf?._case?.address ?? '',
      writingStyle: copyOf?.writingStyle ?? EWritingStyle.Unspecified,
      writingTargetAudiences: copyOf?.targetAudiences?.length
        ? copyOf.targetAudiences
            .map((e) => e)
            .filter((e) => e !== EWritingTargetAudience.Unspecified)
        : [],
    },
  });

  const onSubmit = handleSubmit(async () => {
    if (!selectedSuggestion || !selectedSuggestion.data.id) return;

    try {
      setLoadingMessage({
        message: 'Udarbejder boligtekst...',
        icon: 'pencil-icon',
      });

      const textCollection = await createTextEntriesUsingAiAsync({
        textCollectionWithAiCreateRequest: {
          address: selectedSuggestion.forslagstekst,
          dawaAddressId: selectedSuggestion.data.id,
          sellingPoints: sellingPoints.filter((sp) => sp.trim()),
          writingStyle: watch('writingStyle'),
          writingTargetAudiences: watch('writingTargetAudiences'),
          purposes: outputFormats
            .filter((e) => e.enabled)
            .map((of) => ({
              textPurpose: of.key,
              contentLength: of.length,
            })),
        },
      });

      setLoadingMessage(null);
      onSuccess?.(textCollection.id);
    } catch (error) {
      setLoadingMessage(null);
    }
  });

  return (
    <>
      {loadingMessage ? (
        <FullscreenLoadingWithText
          text={loadingMessage.message}
          icon={loadingMessage.icon}
        />
      ) : null}

      <form onSubmit={onSubmit} className="space-y-2">
        <div className="flex flex-col pt-2 gap-4">
          <ul className="steps steps-horizontal w-full">
            {STEPS.map((s, index) => (
              <li
                onClick={() => setCurrentStep(Math.min(currentStep, s))}
                id={`step-${index + 1}`}
                key={index}
                className={twMerge(
                  'step !min-w-32 pb-4 md:pb-0',
                  s == currentStep && 'font-bold',
                  s <= currentStep &&
                    'cursor-pointer step-primary after:!text-white hover:font-bold hover:step-info'
                )}
              >
                <span>{translateStep(s)}</span>
              </li>
            ))}
          </ul>
          <div className="divider"></div>
          {currentStep === EStep.Address ? (
            <>
              <AutocompleteAddress
                label={
                  <p>
                    Adresse <span className="text-red-500">*</span>
                  </p>
                }
                suggestAddresses={true}
                registration={() => register('address', { required: true })}
                formWatch={watch('address') || ''}
                formSetValue={(value: string) => setValue('address', value)}
                onSuggestionSelectedFn={setSelectedSuggestion}
              />
            </>
          ) : currentStep === EStep.SellingPoints ? (
            <>
              {sellingPoints.map((_, index) => (
                <div className="form-control" key={index}>
                  <label className="label" htmlFor={`selling-point-${index}`}>
                    <span className="label-text">
                      Købsargument {index + 1}{' '}
                    </span>
                  </label>
                  <input
                    key={index}
                    id={`selling-point-${index}`}
                    type="text"
                    className="input input-bordered"
                    placeholder={`Købsargument ${index + 1}`}
                    value={sellingPoints[index]}
                    onChange={(e) => {
                      const newSellingPoints = [...sellingPoints];
                      newSellingPoints[index] = e.target.value;
                      setSellingPoints(newSellingPoints);
                    }}
                  />
                </div>
              ))}

              {sellingPoints.length < MAX_SELLING_POINTS ? (
                <button
                  type="button"
                  className="btn btn-sm btn-outline w-fit"
                  disabled={
                    sellingPoints.length >= MAX_SELLING_POINTS ||
                    !!loadingMessage
                  }
                  onClick={() => {
                    setSellingPoints([...sellingPoints, '']);
                  }}
                >
                  Tilføj endnu et købsargument
                </button>
              ) : null}
            </>
          ) : currentStep === EStep.Preferences ? (
            <>
              <div className="form-control">
                <label className="label" htmlFor="writing-style">
                  <span className="label-text">Skrivestil</span>
                </label>
                <select
                  {...register('writingStyle')}
                  id="writing-style"
                  className="select input-bordered"
                  disabled={!!loadingMessage}
                >
                  {Object.values(EWritingStyle).map((ws) => (
                    <option key={ws} value={ws}>
                      {writingStyleTranslate(ws)}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-control">
                <LabelWithHelperText
                  label="Målgruppe"
                  inDialog={true}
                  htmlFor="writingTargetAudiences"
                  helperText="Vi anbefaler at vælge én målgruppe for at få det bedste resultat. Du er dog velkommen til at vælge flere målgrupper. Hvis ingen målgrupper vælges, vil teksten blive skrevet til en generel målgruppe."
                />
                <EntitySelectMultiple<ITargetAudience>
                  data={targetAudiences}
                  renderFormat={(audience) => <>{audience.name}</>}
                  disabled={!!loadingMessage}
                  inDialog={true}
                  initialValue={[]}
                  searchPropertyKey="name"
                  maxSelections={2}
                  onSelect={(audiences) => {
                    setValue(
                      'writingTargetAudiences',
                      audiences?.map((e) => e.id) ?? []
                    );
                  }}
                />

                <input
                  {...register('writingTargetAudiences', { required: false })}
                  type="text"
                  className="hidden"
                />
              </div>

              <hr />

              <h3 className="font-bold text-md">Formater</h3>

              {outputFormats.map((of, index) => (
                <div
                  key={index}
                  className="form-control flex items-center justify-between"
                  style={{ flexDirection: 'row' }}
                >
                  <div className="text-left w-32">
                    <LabelWithHelperText
                      htmlFor={'opt-' + of.key}
                      inDialog={true}
                      position="right"
                      label={textPurposeTranslate(of.key)}
                      helperText={`Til ${textPurposeTranslate(
                        of.key
                      ).toLowerCase()} kan du vælge mellem ${of.minLength} og ${of.maxLength} tegn. Bemærk, at dette er for at guide Smart Text til at skrive teksten, og at den endelige tekst kan variere i længde.`}
                    />
                  </div>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary mx-4"
                    id={'opt-' + of.key}
                    checked={of.enabled}
                    onChange={(e) => {
                      const newOutputFormats = [...outputFormats];
                      newOutputFormats[index].enabled = e.target.checked;
                      setOutputFormats(newOutputFormats);
                    }}
                  />
                  <input
                    type="number"
                    className={twMerge(
                      'input input-bordered w-24',
                      !of.enabled && 'disabled'
                    )}
                    value={of.length}
                    disabled={!of.enabled || !!loadingMessage}
                    onChange={(e) => {
                      const newOutputFormats = [...outputFormats];
                      newOutputFormats[index].length = parseInt(e.target.value);
                      setOutputFormats(newOutputFormats);
                    }}
                    onBlur={(e) => {
                      let newValue = parseInt(e.target.value);
                      if (isNaN(newValue)) {
                        newValue = of.minLength;
                      }
                      if (newValue < of.minLength) {
                        newValue = of.minLength;
                      }
                      if (newValue > of.maxLength) {
                        newValue = of.maxLength;
                      }
                      const newOutputFormats = [...outputFormats];
                      newOutputFormats[index].length = newValue;
                      setOutputFormats(newOutputFormats);
                    }}
                    min={of.minLength}
                    max={of.maxLength}
                  />
                </div>
              ))}
            </>
          ) : null}
        </div>

        {
          // TODO: Remove when loading indicator supports being on top of modals
        }
        {loadingMessage ? (
          <InlineLoading
            text={loadingMessage.message}
            icon={loadingMessage.icon}
          />
        ) : null}

        <div className="flex justify-center space-x-4 pt-4">
          {currentStep !== STEPS[STEPS.length - 1] ? (
            <button
              className="btn btn-primary"
              disabled={
                !!loadingMessage ||
                (currentStep === EStep.Address && !selectedSuggestion) ||
                (currentStep === EStep.SellingPoints &&
                  sellingPoints.filter((sp) => sp?.trim()).length <
                    MIN_SELLING_POINTS)
              }
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setCurrentStep((e) => e + 1);
              }}
            >
              Fortsæt
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                !!loadingMessage || outputFormats.every((of) => !of.enabled)
              }
            >
              Lav boligtekst
            </button>
          )}

          {onCancel && !loadingMessage ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
        </div>
      </form>
    </>
  );
};
