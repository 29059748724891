import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { useGetTickets } from 'api/useServiceTicketApi';
import { ServiceTicketsTable } from './ServiceTicketsTable';
import { ServiceTicketCreate } from './ServiceTicketCreate';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';

export const ServiceTickets = () => {
  const { user } = useAuth();
  const { state } = useTableQueryState();

  if (state.sorting.length === 0) {
    state.setSorting([{ id: 'lastModifiedUtc', desc: true }]);
  }

  const { data: tickets, isFetching: isFetching } = useGetTickets(false, state);

  return (
    <Page
      title={user?.organization.name}
      breadcrumbs={[
        { name: user?.organization.name ?? '' },
        { name: 'Support' },
      ]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <PermissionProtectedComponent
          permissions={[EPermission.ServiceTicketWrite]}
        >
          <ServiceTicketCreate
            trigger={
              <button className="btn btn-primary">Opret support ticket</button>
            }
          />
        </PermissionProtectedComponent>
      </div>
      <ServiceTicketsTable
        data={tickets}
        isFetching={isFetching}
        queryState={state}
        showOrganizationColumn={false}
        showSupportersColumn={false}
      />
    </Page>
  );
};
