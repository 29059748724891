import {
  EBranchMutationType,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationResponse,
  MediaSequenceBranchMutationUpdateRequest,
  MediaSequenceResponse,
} from 'api/core';
import { useMediaSequenceSetup } from './MediaSequenceSetupProvider';
import { AddBackgroundMusic } from 'pages/MediaOrbit/components/MediaSequenceBranchMutation/Types/AddBackgroundMusic';
import {
  useCreateMediaSequenceBranchMutation,
  useDeleteMediaSequenceBranchMutation,
  useGetMediaSequenceBranchMutation,
  useUpdateMediaSequenceBranchMutation,
} from 'api/useMediaSequenceBranchesApi';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { useMemo } from 'react';

interface MediaSequenceSetupSoundProps {
  mediaSequence: MediaSequenceResponse;
  disabled: boolean;
}

export const MediaSequenceSetupSound = ({
  mediaSequence,
  disabled,
}: MediaSequenceSetupSoundProps) => {
  const { handlePrevious, handleNext } = useMediaSequenceSetup();

  // Check if there is an existing branch mutation for this type
  const existingBranchMutation = useMemo(() => {
    return mediaSequence.branchMutations.find(
      (mutation) => mutation.type === EBranchMutationType.AddBackgroundMusic
    );
  }, [mediaSequence.branchMutations]);

  // Get the existing branch mutation
  const { data: targetBranchMutation, isLoading } =
    useGetMediaSequenceBranchMutation(existingBranchMutation?.id);

  return (
    <>
      {!isLoading ? (
        <MediaSequenceSetupSoundInner
          mediaSequence={mediaSequence}
          targetBranchMutation={targetBranchMutation}
          disabled={disabled}
        />
      ) : null}
      <div className="flex flex-col mt-4 mb-8">
        <div id="step-navigation" className="join justify-center mt-2">
          <button className="join-item btn" onClick={handlePrevious}>
            Forrige trin
          </button>
          <button className="join-item btn" onClick={handleNext}>
            Næste trin
          </button>
        </div>
      </div>
    </>
  );
};

interface MediaSequenceSetupSoundInnerProps {
  mediaSequence: MediaSequenceResponse;
  targetBranchMutation?: MediaSequenceBranchMutationResponse;
  disabled: boolean;
}

const MediaSequenceSetupSoundInner = ({
  mediaSequence,
  targetBranchMutation,
  disabled,
}: MediaSequenceSetupSoundInnerProps) => {
  const [deleteTarget, setDeleteTarget] =
    useState<MediaSequenceBranchMutationResponse | null>(null);

  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateMediaSequenceBranchMutation();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceBranchMutation();

  const methods = useForm<
    | MediaSequenceBranchMutationCreateRequest
    | MediaSequenceBranchMutationUpdateRequest
  >({
    defaultValues: {
      type: EBranchMutationType.AddBackgroundMusic,
      ...targetBranchMutation,
    },
  });

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = methods;

  // We have to disable the form like this, if we set it in the useForm constructor it will not load in default values
  useEffect(() => {
    control._disableForm(disabled);
  }, [control, disabled]);

  const onSubmit = handleSubmit(async (result) => {
    if (targetBranchMutation) {
      await updateAsync({
        id: targetBranchMutation.id,
        mediaSequenceBranchMutationUpdateRequest: result,
      });
    } else {
      if (!mediaSequence.id) return;
      await createAsync({
        id: mediaSequence.id,
        mediaSequenceBranchMutationCreateRequest: result,
      });
    }
  });

  return (
    <div id="audio-overview">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className="space-y-2">
          <AddBackgroundMusic
            key={targetBranchMutation?.id}
            targetBranchMutation={targetBranchMutation}
            projectId={mediaSequence?._case?.project.id}
            mediaSequence={mediaSequence}
            inDialog={false}
            deleteButton={{
              show: !!targetBranchMutation,
              disabled:
                disabled ||
                isPendingCreate ||
                isPendingUpdate ||
                !targetBranchMutation,
              text: 'Fjern lyd',
              onClick: () => {
                if (targetBranchMutation) {
                  setDeleteTarget(targetBranchMutation);
                }
              },
            }}
            saveButton={{
              show: true,
              disabled:
                disabled ||
                isPendingCreate ||
                isPendingUpdate ||
                !isDirty ||
                !isValid,
              text: targetBranchMutation ? 'Opdater lyd' : 'Tilføj lyd',
            }}
          />
        </form>
      </FormProvider>
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          title={`lyden`}
          titlePropertyKey={'id'}
          getter={useGetMediaSequenceBranchMutation}
          deleter={useDeleteMediaSequenceBranchMutation}
        />
      ) : null}
    </div>
  );
};
