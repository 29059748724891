import {
  EMediaSequenceState,
  EPermission,
  ETranscriptionState,
  MediaSequenceResponse,
  OrganizationAuthResponse,
  ProjectResponse,
  ServiceTicketStateResponse,
} from 'api/core';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { MediaSequenceCreate } from 'pages/MediaOrbit/components/MediaSequence/MediaSequenceCreate';
import { ProjectCreate } from 'pages/Project/components/Project/ProjectCreate';
import { PublicationCreate } from 'pages/Publications/PublicationCreate';
import { SmartTextCreate } from 'pages/SmartText/SmartTextCreate';

export interface MenuGroup {
  id?: string;
  title: React.ReactNode;
  items: MenuItem[];
  permissions?: EPermission[];
  badge?: string;
}

export interface MenuItem {
  id?: string;
  title: React.ReactNode;
  icon: JSX.Element;
  to?: string;
  partialPathMatch?: boolean;
  permissions?: EPermission[];
  indicator?: boolean;
  indicatorType?: 'info' | 'error';
  childrenItems?: MenuItem[];
  defaultOpen?: boolean;
}

export const getSidebarItems = (
  organization: OrganizationAuthResponse,
  hasPermissions: (permissions?: EPermission[]) => boolean,
  projects: ProjectResponse[],
  mediaSequences: MediaSequenceResponse[],
  ticketState?: ServiceTicketStateResponse
): MenuGroup[] => {
  return [
    {
      id: 'sidebar-home',
      title: 'Hjem',
      items: [
        {
          icon: <AnimatedIcon icon="home-icon" className="w-7 h-7" />,
          title: 'Oversigt',
          to: '',
        },
        // These should always be visible, even when not logged in..
        /*{
          icon: <HomeIcon size={20} />,
          title: 'Forretningsbetingelser',
          to: 'terms-and-conditions',
        },
        {
          icon: <HomeIcon size={20} />,
          title: 'Privatlivspolitik',
          to: 'privacy-policy',
        },*/
        // {
        //   icon: <PackageSearchIcon size={20} />,
        //   title: 'Moduler',
        //   to: 'products',
        // },
      ],
    },
    {
      id: 'sidebar-projects',
      title: hasPermissions([EPermission.ModuleProjectMultiCase])
        ? 'Projekter'
        : 'Sager',
      permissions: [EPermission.ModuleProject, EPermission.ProjectRead],
      items: [
        {
          icon: <AnimatedIcon icon="plus-icon" className="w-7 h-7" />,
          title: (
            <div>
              <ProjectCreate
                trigger={
                  <p>
                    {hasPermissions([EPermission.ModuleProjectMultiCase])
                      ? 'Opret projekt'
                      : 'Opret sag'}
                  </p>
                }
              />
            </div>
          ),
          permissions: [EPermission.ProjectWrite],
        },
        ...projects.map(
          (project) =>
            ({
              icon: project.singleCase ? (
                <AnimatedIcon icon="home-icon" className="w-7 h-7" />
              ) : (
                <AnimatedIcon icon="building-icon" className="w-7 h-7" />
              ),
              title: project.name,
              to: '',
              indicator:
                project.estateOrbitEnabled && project.translationsMissing,
              indicatorType:
                project.estateOrbitEnabled && project.translationsMissing
                  ? 'error'
                  : undefined,
              childrenItems: [
                ...(project.singleCase
                  ? [
                      {
                        icon: (
                          <AnimatedIcon
                            icon="folder-icon"
                            className="w-7 h-7"
                          />
                        ),
                        title: 'Sag',
                        to: `projects/${project.id}/case`,
                      } as MenuItem,
                    ]
                  : [
                      {
                        icon: (
                          <AnimatedIcon
                            icon="archive-icon"
                            className="w-7 h-7"
                          />
                        ),
                        title: 'Sager',
                        to: `projects/${project.id}/cases`,
                      } as MenuItem,
                    ]),
                {
                  icon: <AnimatedIcon icon="list-icon" className="w-7 h-7" />,
                  title: 'Felter',
                  to: `projects/${project.id}/fields`,
                  permissions: [EPermission.FieldRead],
                },
                {
                  icon: <AnimatedIcon icon="asset-icon" className="w-7 h-7" />,
                  title: 'Filer',
                  to: `projects/${project.id}/assets`,
                },
                ...(project.estateOrbitEnabled
                  ? [
                      {
                        icon: (
                          <AnimatedIcon
                            icon="estate-orbit-icon"
                            className="w-7 h-7"
                          />
                        ),
                        title: 'Boligvælger',
                        permissions: [EPermission.ProjectRead],
                        childrenItems: [
                          {
                            icon: (
                              <AnimatedIcon
                                icon="preview-icon"
                                className="w-7 h-7"
                              />
                            ),
                            title: 'Forhåndsvisning',
                            to: `projects/${project.id}/estate-orbit/preview`,
                            permissions: [EPermission.EstateOrbitToggle],
                          } as MenuItem,
                          {
                            icon: (
                              <AnimatedIcon
                                icon="section-icon"
                                className="w-7 h-7"
                              />
                            ),
                            title: 'Sektioner',
                            to: `projects/${project.id}/estate-orbit/sections`,
                            permissions: [EPermission.EstateOrbitToggle],
                          } as MenuItem,
                          {
                            icon: (
                              <AnimatedIcon
                                icon="angle-icon"
                                className="w-7 h-7"
                              />
                            ),
                            title: 'Vinkler',
                            to: `projects/${project.id}/estate-orbit/angles`,
                            permissions: [EPermission.EstateOrbitToggle],
                          } as MenuItem,
                        ],
                      } as MenuItem,
                    ]
                  : []),
                ...(!project.singleCase
                  ? [
                      {
                        icon: (
                          <AnimatedIcon icon="zoom-icon" className="w-7 h-7" />
                        ),
                        title: 'Statistik',
                        to: `projects/${project.id}/statistics`,
                        permissions: [EPermission.ProjectWrite],
                      } as MenuItem,
                    ]
                  : []),
                {
                  icon: (
                    <AnimatedIcon icon="languages-icon" className="w-7 h-7" />
                  ),
                  title: 'Oversættelser',
                  to: `projects/${project.id}/estate-orbit/translations`,
                  indicator: project.translationsMissing,
                  indicatorType: project.translationsMissing
                    ? 'error'
                    : undefined,
                } as MenuItem,
                {
                  icon: (
                    <AnimatedIcon icon="settings-icon" className="w-7 h-7" />
                  ),
                  title: 'Indstillinger',
                  to: `projects/${project.id}/settings`,
                  permissions: [EPermission.ProjectDelete],
                },
              ],
            }) as MenuItem
        ),
        {
          icon: <AnimatedIcon icon="homes-icon" className="w-7 h-7" />,
          title: hasPermissions([EPermission.ModuleProjectMultiCase])
            ? 'Alle projekter'
            : 'Alle sager',
          to: 'projects',
        },
      ],
    },
    {
      id: 'sidebar-media-sequence',
      title: 'Smart Video',
      permissions: [
        EPermission.ModuleMediaOrbit,
        EPermission.MediaSequenceRead,
      ],
      items: [
        {
          icon: <AnimatedIcon icon="plus-icon" className="w-7 h-7" />,
          title: (
            <div>
              <MediaSequenceCreate trigger={<p>Opret video</p>} />
            </div>
          ),
        },
        ...(mediaSequences.map((mediaSequence) => ({
          icon:
            mediaSequence.state === EMediaSequenceState.Processed ? (
              <AnimatedIcon icon="movie-icon" className="w-7 h-7" />
            ) : (
              <AnimatedIcon icon="movie-edit-icon" className="w-7 h-7" />
            ),
          title: mediaSequence.name,
          to: '',
          indicator:
            mediaSequence.transcriptionState ===
              ETranscriptionState.Generated ||
            mediaSequence.state === EMediaSequenceState.Failed,
          indicatorType:
            mediaSequence.state === EMediaSequenceState.Failed
              ? 'error'
              : undefined,
          childrenItems: [
            {
              icon: <AnimatedIcon icon="list-icon" className="w-7 h-7" />,
              title: 'Opsætning',
              to: `media-sequences/${mediaSequence.id}/setup`,
              indicator:
                mediaSequence.transcriptionState ===
                  ETranscriptionState.Generated ||
                mediaSequence.state === EMediaSequenceState.Failed,
              indicatorType:
                mediaSequence.state === EMediaSequenceState.Failed
                  ? 'error'
                  : undefined,
            } as MenuItem,
            ...(mediaSequence.state !== EMediaSequenceState.Draft
              ? [
                  {
                    icon: (
                      <AnimatedIcon icon="audit-log-icon" className="w-7 h-7" />
                    ),
                    title: 'Tidslinje',
                    to: `media-sequences/${mediaSequence.id}/events`,
                    permissions: [EPermission.MediaSequenceEditorTimeline],
                  } as MenuItem,
                ]
              : []),
            {
              icon: <AnimatedIcon icon="settings-icon" className="w-7 h-7" />,
              title: 'Indstillinger',
              to: `media-sequences/${mediaSequence.id}/settings`,
            } as MenuItem,
          ],
        })) as MenuItem[]),
        {
          icon: <AnimatedIcon icon="movies-icon" className="w-7 h-7" />,
          title: 'Alle videoer',
          to: 'media-sequences',
        },
        {
          icon: <AnimatedIcon icon="folder-icon" className="w-7 h-7" />,
          title: 'Mapper',
          to: 'media-sequences/folders',
          partialPathMatch: true,
          permissions: [EPermission.FolderRead],
        },
      ],
    },
    {
      id: 'sidebar-smart-text',
      title: 'Smart Text',
      permissions: [
        EPermission.ModuleSmartText,
        EPermission.TextCollectionRead,
      ],
      items: [
        {
          permissions: [EPermission.TextCollectionWrite],
          icon: <AnimatedIcon icon="plus-icon" className="w-7 h-7" />,
          title: (
            <div>
              <SmartTextCreate trigger={<p>Opret boligtekst</p>} />
            </div>
          ),
        },
        {
          icon: <AnimatedIcon icon="documents-icon" className="w-7 h-7" />,
          title: 'Alle tekster',
          to: 'smart-texts',
        },
      ],
    },
    {
      id: 'sidebar-publications',
      title: 'Smart Planner',
      badge: 'Kommer snart',
      permissions: [EPermission.ModuleSocialMedia, EPermission.PublicationRead],
      items: [
        {
          permissions: [EPermission.PublicationWrite],
          icon: <AnimatedIcon icon="plus-icon" className="w-7 h-7" />,
          title: (
            <div>
              <PublicationCreate trigger={<p>Opret publicering</p>} />
            </div>
          ),
        },
        {
          icon: <AnimatedIcon icon="social-media-icon" className="w-7 h-7" />,
          title: 'Alle publiceringer',
          to: 'publications',
        },
      ],
    },
    {
      id: 'sidebar-organization',
      title: organization.name,
      items: [
        {
          icon: <AnimatedIcon icon="avatars-icon" className="w-7 h-7" />,
          title: 'Brugere',
          to: 'organization/users',
          permissions: [EPermission.UserWrite],
        },
        {
          icon: <AnimatedIcon icon="asset-icon" className="w-7 h-7" />,
          title: 'Alle filer',
          to: 'organization/assets',
        },
        // {
        //   icon: <AnimatedIcon icon="folder-icon" className="w-7 h-7" />,
        //   title: 'Alle mapper',
        //   to: 'organization/folders',
        // },
        {
          icon: <AnimatedIcon icon="share-icon" className="w-7 h-7" />,
          title: 'Tilknyt sociale medier',
          to: 'organization/social-media',
          permissions: [
            EPermission.ModuleSocialMedia,
            EPermission.ExternalIntegrationWrite,
          ],
        },
        {
          icon: <AnimatedIcon icon="audit-log-icon" className="w-7 h-7" />,
          title: 'Seneste sessioner',
          to: 'organization/sessions',
          permissions: [EPermission.OrganizationSeeSessionStatistics],
        },
        {
          icon: <AnimatedIcon icon="support-icon" className="w-7 h-7" />,
          title: 'Support',
          to: 'organization/service-tickets',
          indicator:
            ticketState?.awaitingCustomerCount &&
            ticketState?.awaitingCustomerCount > 0
              ? true
              : false,
          permissions: [EPermission.ServiceTicketRead],
          partialPathMatch: true,
        },
      ],
    },
    {
      title: 'Administration',
      permissions: [EPermission.InterOrganizationalAccess],
      items: [
        {
          icon: <AnimatedIcon icon="support-icon" className="w-7 h-7" />,
          permissions: [EPermission.ServiceTicketSupporter],
          title: 'Alle supportsager',
          to: 'admin/service-tickets',
          indicator:
            ticketState?.awaitingCustomerCount &&
            ticketState?.awaitingCustomerCount > 0
              ? true
              : false,
        },
        {
          icon: (
            <AnimatedIcon icon="customer-management-icon" className="w-7 h-7" />
          ),
          title: 'Kundestyring',
          to: '',
          childrenItems: [
            {
              icon: <AnimatedIcon icon="audit-log-icon" className="w-7 h-7" />,
              permissions: [EPermission.AuditLogRead],
              title: 'Audit Logs',
              to: 'admin/customers/audit-logs',
            },
            {
              icon: <AnimatedIcon icon="factory-icon" className="w-7 h-7" />,
              permissions: [EPermission.UserRead, EPermission.OrganizationRead],
              title: 'Organisationer',
              to: 'admin/customers/organizations',
            },
            {
              icon: <AnimatedIcon icon="avatars-icon" className="w-7 h-7" />,
              permissions: [EPermission.UserRead, EPermission.OrganizationRead],
              title: 'Brugere',
              to: 'admin/customers/users',
            },
            {
              icon: <AnimatedIcon icon="color-icon" className="w-7 h-7" />,
              permissions: [EPermission.VisualIdentityRead],
              title: 'Visuelle identiteter',
              to: 'admin/customers/visual-identities',
            },
            {
              icon: <AnimatedIcon icon="emails-icon" className="w-7 h-7" />,
              permissions: [EPermission.MailingListRead],
              title: 'Mail lister',
              to: 'admin/customers/mail-lists',
            },
          ],
        },
        {
          icon: <AnimatedIcon icon="settings-icon" className="w-7 h-7" />,
          title: 'Smart Video',
          to: '',
          childrenItems: [
            {
              icon: <AnimatedIcon icon="animation-icon" className="w-7 h-7" />,
              permissions: [EPermission.DynamicTemplateWrite],
              title: 'Dynamiske overlejringer',
              to: 'admin/media-sequence/dynamic-templates',
            },
            {
              icon: <AnimatedIcon icon="pages-icon" className="w-7 h-7" />,
              permissions: [
                EPermission.MediaSequenceGlobalTemplateAdministrator,
              ],
              title: 'Kategorier',
              to: 'admin/media-sequence/categories',
            },
            {
              icon: <AnimatedIcon icon="canvas-icon" className="w-7 h-7" />,
              permissions: [
                EPermission.MediaSequenceGlobalTemplateAdministrator,
              ],
              title: 'Skabeloner',
              to: 'admin/media-sequence/templates',
            },
          ],
        },
        {
          icon: <AnimatedIcon icon="settings-icon" className="w-7 h-7" />,
          title: 'Konfiguration',
          to: '',
          childrenItems: [
            {
              icon: <AnimatedIcon icon="languages-icon" className="w-7 h-7" />,
              title: 'Sprogstyring',
              to: 'admin/config/languages',
            },
          ],
        },
      ],
    },
  ];
};
